:root {
  --p-action-primary: rgba(40, 70, 235);
  --p-action-primary-hovered: rgb(33, 52, 158);
  --p-action-primary-pressed: rgba(40, 70, 235);
  --p-action-primary-depressed: rgba(40, 70, 235);
  --p-interactive: rgba(40, 70, 235);
  --p-text-primary: rgba(40, 70, 235);
  --p-text-primary-hovered: rgb(33, 52, 158);
  --p-text-primary-pressed: rgba(40, 70, 235);
  --p-action-primary-disabled: rgba(185, 205, 255, 1);
}

.textSuccess {
  color: green;
}

.Polaris-Frame-Loading__Level {
  background-color: var(--p-text-primary);
}

.Polaris-Button--primary.Polaris-Button--disabled,
.Polaris-Button--destructive.Polaris-Button--disabled {
  color: rgba(255, 255, 255, 0.7);
}

.Polaris-Navigation {
  min-width: 12rem;
  max-width: 12rem;
}

.Polaris-Navigation__PrimaryNavigation {
  overflow: hidden;
}

.Polaris-Frame--hasNav .Polaris-Frame__Main {
  padding-left: 12rem;
}

.bg1 {
  background-color: rgba(40, 70, 235);
}

.form-container {
  width: 60%;
  padding-top: var(--p-space-8);
  padding-bottom: var(--p-space-4);
}

.form-container .Polaris-TextField__Input {
  height: 3rem;
}

.form-container .Polaris-Button--primary.Polaris-Button--disabled {
  height: 3rem;
}

.store-container .Polaris-TextField__Input {
  height: 3rem;
}

.store-container .Polaris-Button--primary.Polaris-Button--disabled {
  height: 3rem;
}

.store-container .Polaris-Button--primary {
  height: 3rem;
}

.form-container .Polaris-Button--primary {
  height: 3rem;
}

.store-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60vh;
}

/* .store-container .Polaris-TextField__Input {
  height: 3rem;
} */
.store-container > div {
  width: 40%;
}

.store-container .Polaris-TextField__Prefix {
  margin-left: 0;
  background-color: #e9e9e9;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}

.store-container .Polaris-TextField__Suffix {
  margin-right: 0;
  background-color: #e9e9e9;
  border-bottom-right-radius: 2px;
  border-top-right-radius: 2px;
}

.tableHead {
  min-width: 5rem;
  max-width: 20rem;
  white-space: initial;
  float: right;
}

.store-container .Polaris-TextField__Input {
  height: 3rem;
  border: none;
}

.store-container .Polaris-TextField__Backdrop {
  border: none;
}

.Polaris-Navigation__ListItem {
  margin-bottom: var(--p-space-4);
}

.Polaris-Navigation__PrimaryNavigation {
  padding-top: var(--p-space-6);
}

.Polaris-Spinner--sizeSmall svg {
  height: 1.5rem;
  width: 2rem;
}

.Polaris-DataTable thead {
  background-color: var(--p-surface-subdued);
}

.cell {
  min-width: 5rem;
  max-width: 20rem;
  overflow: hidden;
  text-overflow: ellipsis;
}

.mostCell {
  min-width: 5rem;
  max-width: 12rem;
  overflow: hidden;
  text-overflow: ellipsis;
}

.customCell {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.innerCell {
  display: flex;
  align-items: center;
  gap: 10px;
}

.strike-and-fade {
  text-decoration: line-through;
  opacity: 0.6;
}

.Polaris-SkeletonDisplayText--sizeMedium {
  --pc-skeleton-display-text-height-not-condensed: var(--p-font-line-height-2);
}
.form-wizard-steps {
  margin-left: 10%;
}
.react-form-wizard .wizard-nav-pills li,
.react-form-wizard .wizard-nav-pills a {
  align-items: baseline;
}
.wizard-progress-with-circle {
  width: 70%;
  margin-left: 10%;
}

@media screen and (min-width: 319px) and (max-width: 500px) {
  .bg1 img {
    width: 120px;
  }

  .bg1 :nth-child(1).Polaris-Inline > .Polaris-Box {
    padding: 0;
    padding: var(--p-space-2);
    margin-top: var(--p-space-4);
  }

  .form-container {
    width: 75%;
  }

  .is-already {
    border: 1px solid red;
    margin: auto;
    width: 100%;
    padding: var(--p-space-8);
    padding-top: var(--p-space-12);
  }

  .store-container {
    height: 30vh;
  }

  .store-container > div {
    width: 100%;
  }

  .Polaris-Frame--hasNav .Polaris-Frame__Main {
    padding-left: 0;
  }
}

@media screen and (min-width: 500px) and (max-width: 768px) {
  .Polaris-Frame--hasNav .Polaris-Frame__Main {
    padding-left: 0;
  }
}

.Polaris-Icon {
  margin: 0;
}

.navigationMenuContainer {
  margin-bottom: 1.5rem;
  display: flex;
  /* cursor: pointer; */
  text-decoration: none;
  color: black;
  flex-direction: column;
}

.navigationMenuContainer_subMenu {
  margin-top: 1.5rem;
  margin-left: 1.75rem;
  display: flex;
  /* cursor: pointer; */
  text-decoration: none;
  color: black;
  flex-direction: column;
}

.navigationMenu {
  /* padding-bottom: 0.3rem; */
  display: flex;
  cursor: pointer;
  text-decoration: none;
  color: black;
}

.navigationMenuOnClicked {
  /* margin-bottom: 0.3rem; */
  display: flex;
  cursor: pointer;
  color: rgba(40, 70, 235, 1);
  text-decoration: none;
}

.navigationMenuImage {
  margin: 0;
  padding: 0 0.5rem 0 0.7rem;
}

.linkStyle {
  text-decoration: none;
  color: rgba(40, 70, 235);
}

.linkStyle:visited,
.linkStyle:active {
  color: rgba(40, 70, 235);
}

.cohort-cell {
  padding: 0;
}

@keyframes blinking {
  0% {
    background-color: #f0f0f0;
  }

  /* Light color */
  50% {
    background-color: #ffffff;
  }

  /* White (current background) */
  100% {
    background-color: #f0f0f0;
  }

  /* Light color */
}
