/* .container {
  width: 70%;
}

/* Responsive styles for smaller screens */
/* @media (max-width: 768px) {
  .container {
    width: 90%;
  }
} */

/* Responsive styles for even smaller screens */
/* @media (max-width: 480px) {
  .container {
    width: 100%;
  }
}  */

/* body {
  font-family: Arial, sans-serif;
  background-color: #ffffff;
  padding: 20px;
  text-align: center;
} */
#nps-score-buttons button {
  padding: 10px 20px;
  margin: 5px;
  border: none;
  color: white;
  cursor: pointer;
  border-radius: 4px;
  transition: transform 0.3s, box-shadow 0.3s;
}
#nps-score-buttons button:nth-child(1) {
  background: #d12000;
} /* 1 */
#nps-score-buttons button:nth-child(2) {
  background: #ed6000;
} /* 2 */
#nps-score-buttons button:nth-child(3) {
  background: #ff8c00;
} /* 3 */
#nps-score-buttons button:nth-child(4) {
  background: #ffad00;
} /* 4 */
#nps-score-buttons button:nth-child(5) {
  background: #ffd300;
} /* 5 */
#nps-score-buttons button:nth-child(6) {
  background: #ffd200;
} /* 6 - Neutral */
#nps-score-buttons button:nth-child(7) {
  background: #ffd000;
} /* 7 - Neutral */
#nps-score-buttons button:nth-child(8) {
  background: #69eb00;
} /* 8 */
#nps-score-buttons button:nth-child(9) {
  background: #5cb85c;
} /* 9 */
#nps-score-buttons button:nth-child(10) {
  background: #4cae4c;
} /* 10 */
#nps-score-buttons button.selected {
  transform: scale(1.2);
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.07);
}
#nps-score-buttons button:hover {
  transform: scale(1.2);
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.07);
}
.reasons button {
  background-color: #e0e0e0;
  color: black;
}
.reasons {
  margin-top: 20px;
}
.reasons button {
  background-color: transparent;
  color: #007bff;
  border: 2px solid #007bff;
  padding: 10px 20px;
  margin: 5px;
  border-radius: 20px; /* Pill shape */
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.reasons button.selected {
  background-color: #007bff;
  color: white;
}

.reasons button:hover {
  background-color: #0056b3;
  color: white;
  border-color: #0056b3;
}

.product-section {
  border: 2px solid #f0f0f0;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 8px;
}

.product-image {
  max-width: 200px;
  max-height: 80px;
  width: auto;
  height: auto;
  border-radius: 12px;
}

.product-info {
  margin-top: 10px;
  color: #707070; /* Lighter color */
  font-size: 14px; /* Smaller font size */
}

.product-info h3 {
  color: #333333; /* Standard color for product name */
  font-size: 16px; /* Slightly larger font for product name */
}

.prompt {
  color: #707070;
  font-size: 16px;
  margin-top: 20px;
  margin-bottom: 10px;
  font-weight: bold;
}
/* header {
  text-align: center;
  padding: 20px 0;
}

header img {
  max-width: 150px; 
  height: auto;
} */

.footerlogo {
  display: inline-block;
  margin-left: 6px;
  filter: grayscale(100%);
}
.footerlogo:hover {
  display: inline-block;
  margin-left: 6px;
  filter: grayscale(0%);
  transition: filter 0.5s;
}

.submitButton {
  font-family: 'Ubuntu', sans-serif;

  width: 100px;
  height: 40px;
  line-height: 1;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 1px;
  border: 3px solid #8c82fc;
  background: #fff;
  color: #8c82fc;
  border-radius: 40px;
  cursor: pointer;
  overflow: hidden;
  transition: all 0.35s;
}

#success-message {
  background-color: #dff0d8; /* Light green background */
  color: #3c763d; /* Dark green text */
  border-radius: 4px;
  margin-top: 20px;
  padding: 20px;
}

.cta-button {
  background-color: #007bff;
  color: #ffffff;
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 4px;
  display: inline-block;
  margin-top: 20px;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.cta-button:hover {
  transform: scale(1.2);
}
