.verticleBox {
  width: 100%;
  min-height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0.18rem;
  /* padding-bottom: 1rem; */
  border: 1px solid rgb(201, 204, 207);
  width: 100%;
}
.inputVerticle {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;

  outline: none;
  border: none;
  height: fit-content;
}
