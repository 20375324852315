.variable-list {
  margin: 0;
  padding: 0;
}

.variable-list li {
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
}
.variable-list li:hover {
  background-color: #888;
}
