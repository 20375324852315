.base {
  position: relative;
}
.indicator {
  position: absolute;
  top: -20%;
  right: 0%;
  left: 80%;
  bottom: 90%;
  background-color: #e4e5e7;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
